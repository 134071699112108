<template lang="pug">
.page
  el-card.filterCard
    el-form(inline label-width="6em")
      el-form-item(label="搜索")
        el-input(v-model="filter.search")
      el-form-item(label="来源")
        el-select(v-model="filter['source.type']")
          el-option(v-for="i in articleSourceTypeListWithAll" :label="i.name" :value="i.value")
      el-form-item(label="分组")
        el-select(v-model="filter.category")
          el-option(label="全部" value="")
          el-option(v-for="i in articleCategoryList" :label="i.name" :value="i._id")
      el-form-item(label="地区")
        el-select(v-model="filter.city")
          el-option(label="全部" value="")
          el-option(v-for="i in cityList" :label="i.name" :value="i._id")
      el-form-item()
        el-button(type="primary" @click="handleSearch") 搜索
        el-button(type="warning" @click="initData") 重置
  el-card.tableCard
    el-table(:data="list")
      el-table-column(prop="_id" label="id")
      el-table-column(prop="title" label="标题")
      el-table-column(label="分组")
        template(#default="scope")
          span {{ scope.row?.category?.name }}
      el-table-column(label="地区")
        template(#default="scope")
          span {{ scope.row?.city?.name }}
      el-table-column(label="来源")
        template(#default="scope")
          span {{ formatArticleSourceType(scope.row.source?.type) }}
      el-table-column(label="标签")
        template(#default="scope")
          el-tag(size="mini" v-for="i in scope.row?.tagList || []" style="margin-right:5px") {{ i }}
      el-table-column(label="状态")
        template(#default="scope")
          div 阅读量：{{ scope.row?.status?.readCount || 0 }}
          div 收藏量：{{ scope.row?.status?.digCount || 0 }}
          div 收藏量：{{ scope.row?.status?.collectCount || 0 }}
          div 分享量：{{ scope.row?.status?.shareCount || 0 }}
      el-table-column(label="关联职位")
        template(#default="scope")
          el-tag(v-if="!scope.row?.jobList?.length" type="info" size="mini") 无
          el-tag(v-for="i in scope.row?.jobList" size="mini") {{ i.name }}
      el-table-column(fixed="right" label="操作" width="120")
        template(#default="scope")
          el-button(type="primary" icon="el-icon-edit" size="mini" plain @click="handleEdit(scope.row)")
          el-popconfirm(title="确定要删除吗？" @confirm="handleDelete(scope.row)")
            template(#reference)
              el-button(type="danger" icon="el-icon-delete" size="mini" plain)
    .footer
      el-pagination(v-model:currentPage="pagination.page"
        v-model:pageSize="pagination.size"
        :pageSizes="[10, 20, 50, 100, 200]"
        :total="pagination.total"
        layout="sizes, prev, pager, next"
        background
        @size-change="handleSearch"
        @current-change="getList")

</template>

<script setup>
import { inject, onMounted, ref } from 'vue'
import { articleSourceTypeListWithAll, formatArticleSourceType } from '@/dict/article'
import { articleCategoryList, getArticleCategoryList, getCityList, cityList } from '@/provider/global'

const { fetch, router } = inject('global')

const loading = ref(false)
const list = ref([])
const pagination = ref({
  page: 1,
  size: 10,
  total: 100
})
const filter = ref({
  category: '',
  city: '',
  ['source.type']: '',
  search: ''
})

function handleSearch() {
  list.value = []
  pagination.value.page = 1
  getList()
}
function initData() {
  filter.value.category = ''
  filter.value.search = ''
  handleSearch()
}
function getList() {
  let params = {
    ...pagination.value
  }
  if (filter.value.search) {
    params.search = filter.value.search
  }
  if (filter.value.category) {
    params.category = filter.value.category
  }
  if (filter.value.city) {
    params.city = filter.value.city
  }
  if (filter.value.source) {
    params['source.type'] = filter.value.source
  }
  fetch.get('/article', { params }).then(res => {
    list.value = res.list
    pagination.value.total = res.page.total
  })
}

function handleEdit(val) {
  router.push(`/article/edit/${val._id}`)
}

onMounted(() => {
  getArticleCategoryList()
  getCityList()
  initData()
})
</script>

<style lang="less" scoped>
.footer {
  text-align: center;
  padding-top: 10px;
}
</style>
